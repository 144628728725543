import { EDIT_VIDEO_URL } from '../const/api.const';
import { wait } from '../lib/wait';

import { IEditingVideoResponse } from './types';

export const awaitEditingVideo = async (jwtToken: string, processId: string): Promise<IEditingVideoResponse> => {
  const response = await fetch(`${EDIT_VIDEO_URL}/${processId}`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    }
  });
  const json = (await response.json()) as IEditingVideoResponse;
  console.log('json', json);

  if (json.status === 'PROCESSING') {
    await wait(5000);

    return awaitEditingVideo(jwtToken, processId);
  }

  return json;
};
