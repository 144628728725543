import { useContext, useEffect } from 'react';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

const getSizeByWidth = (maxWidth: number, width: number, height: number) => ({
  height: Math.round(maxWidth * (height / width)),
  width: maxWidth
});

const getSizeByHeight = (maxHeight: number, width: number, height: number) => ({
  height: maxHeight,
  width: Math.round(maxHeight * (width / height))
});

export const useResizeVideo = () => {
  const editorModel = useContext(EditorModelContext);
  const contentElement = useSubscription(editorModel.player.$contentElement);
  const videoElement = useSubscription(editorModel.player.$videoElement);

  useEffect(() => {
    if (!videoElement || !contentElement) {
      return;
    }

    const handleResize = () => {
      const { videoHeight, videoWidth } = videoElement;
      const { clientHeight, clientWidth } = contentElement;

      if (!videoWidth || !videoHeight || !clientHeight || !clientWidth) {
        return;
      }

      const maxVideoWidth = clientWidth * 0.9;
      const maxVideoHeight = (clientHeight - 190) * 0.9;

      if (videoWidth <= maxVideoWidth && videoHeight <= maxVideoHeight) {
        return;
      }

      const byWidth = getSizeByWidth(maxVideoWidth, videoWidth, videoHeight);

      if (byWidth.width <= maxVideoWidth && byWidth.height <= maxVideoHeight) {
        videoElement.style.width = `${byWidth.width}px`;
        videoElement.style.height = `${byWidth.height}px`;

        return;
      }

      const byHeight = getSizeByHeight(maxVideoHeight, videoWidth, videoHeight);

      if (byHeight.width <= maxVideoWidth && byHeight.height <= maxVideoHeight) {
        videoElement.style.width = `${byHeight.width}px`;
        videoElement.style.height = `${byHeight.height}px`;

        return;
      }

      throw new Error('Video size is too hz');
    };

    handleResize();

    window.addEventListener('resize', handleResize, true);

    return () => {
      window.removeEventListener('resize', handleResize, true);
    };
  }, [contentElement, videoElement]);
};
