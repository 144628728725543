import * as React from 'react';
import { FC } from 'react';

import Box from '@mui/material/Box';

import { PlayPauseButton } from './PlayPauseButton/PlayPauseButton';
import { Timeline } from './Timeline';

export const TimelineWrapper: FC = () => (
  <Box data-testid="TimelineWrapper" sx={{ p: 2, textAlign: 'center' }}>
    <Timeline />
    <PlayPauseButton sx={{ mt: 2 }} />
  </Box>
);
