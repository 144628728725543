'use client';

import { useContext } from 'react';

import { Box, LinearProgress } from '@mui/material';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

export const Timeline = () => {
  const editorModel = useContext(EditorModelContext);

  const currentTime = useSubscription(editorModel.player.$currentTime) ?? 0;
  const videoTime = useSubscription(editorModel.player.$videoTime);

  if (!videoTime) {
    return null;
  }

  const progress = Math.round((currentTime / videoTime) * 100);

  return (
    <Box sx={{ height: 20, mb: 1, mt: 1, width: '100%' }}>
      <LinearProgress value={progress} variant="determinate" />
    </Box>
  );
};
