import { EDIT_VIDEO_URL } from '../const/api.const';

import { awaitEditingVideo } from './awaitEditingVideo.api';
import { IEditingVideoResponse, IOperations } from './types';

export const editVideo = async (jwtToken: string, videoId: string, operations: IOperations[]) => {
  const body = JSON.stringify({
    operations,
    videoId
  });
  const response = await fetch(EDIT_VIDEO_URL, {
    body,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });
  const { id } = (await response.json()) as IEditingVideoResponse;

  return await awaitEditingVideo(jwtToken, id);
};
