import { Atom, ReadOnlyAtom } from '@grammarly/focal';

import { Nullable } from 'common/lib/types/assert';

export interface PlayerState {
  readonly contentElement: Nullable<HTMLDivElement>;
  readonly currentTime: Nullable<number>;
  readonly isPaused: Nullable<boolean>;
  readonly videoElement: Nullable<HTMLVideoElement>;
  readonly videoTime: Nullable<number>;
}

export class PlayerModel {
  public readonly $contentElement: ReadOnlyAtom<Nullable<HTMLDivElement>>;
  public readonly $videoElement: ReadOnlyAtom<Nullable<HTMLVideoElement>>;
  public readonly $isPaused: ReadOnlyAtom<Nullable<boolean>>;
  public readonly $currentTime: ReadOnlyAtom<Nullable<number>>;
  public readonly $videoTime: ReadOnlyAtom<Nullable<number>>;

  constructor(public readonly state: Atom<PlayerState>) {
    this.$contentElement = this.state.lens('contentElement');
    this.$videoElement = this.state.lens('videoElement');
    this.$isPaused = this.state.lens('isPaused');
    this.$currentTime = this.state.lens('currentTime');
    this.$videoTime = this.state.lens('videoTime');
  }

  setContentElement(contentElement: Nullable<HTMLDivElement>) {
    this.state.modify(state => ({ ...state, contentElement }));
  }

  setVideoElement(videoElement: Nullable<HTMLVideoElement>) {
    this.state.modify(state => ({ ...state, videoElement }));
  }

  play() {
    this.state.modify(state => ({ ...state, isPaused: false }));
  }

  pause() {
    this.state.modify(state => ({ ...state, isPaused: true }));
  }

  setCurrentTime(currentTime: number) {
    this.state.modify(state => ({ ...state, currentTime }));
  }

  setVideoTime(videoTime: Nullable<number>) {
    this.state.modify(state => ({ ...state, videoTime }));
  }
}

const DEFAULT_PLAYER_STATE: PlayerState = {
  contentElement: null,
  currentTime: null,
  isPaused: null,
  videoElement: null,
  videoTime: null
};

export const createPlayerModel = (state: Partial<PlayerState> = {}) =>
  new PlayerModel(Atom.create({ ...DEFAULT_PLAYER_STATE, ...state }));
