import * as React from 'react';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { ToolsMenu } from 'common/pages/EditorPage/components/ToolsMenu/ToolsMenu';

import { EditorTranslations } from '../../EditorTranslations.interface';

import { CenterBox, TimelineBox, WrapperBox } from './components';
import { ContentBox } from './ContentBox/ContentBox';

interface Props extends PropsWithChildren {
  readonly header: ReactNode;
  readonly t: EditorTranslations;
  readonly timeline: ReactNode;
  readonly toolsSettings: ReactNode;
}

export const EditorLayout: FC<Props> = ({ children, header, t, timeline, toolsSettings }) => (
  <>
    {header}
    <WrapperBox data-testid="EditorLayout">
      <ToolsMenu t={t}>
        <CenterBox>
          {toolsSettings}
          <ContentBox>{children}</ContentBox>
        </CenterBox>
      </ToolsMenu>
    </WrapperBox>
    <TimelineBox>{timeline}</TimelineBox>
  </>
);
