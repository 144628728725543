import { FC, PropsWithChildren, useContext } from 'react';

import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

import { useSubscription } from 'common/hooks/useSubscription';

import { EditorModelContext } from '../../models/EditorModel';

export const WrapperBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => (
  <Box data-testid="WrapperBox" sx={{ mt: 8 }} {...props}>
    {children}
  </Box>
);

export const CenterBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => (
  <Box data-testid="CenterBox" sx={{ display: 'flex' }} {...props}>
    {children}
  </Box>
);

export const ToolsSettingsBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => (
  <Box
    data-testid="ToolsSettingsBox"
    sx={{
      background: 'rgba(33, 150, 243, 0.08)',
      borderRight: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'center',
      width: { lg: 400, md: 250 }
    }}
    {...props}
  >
    {children}
  </Box>
);

export const TimelineBox: FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => {
  const editorModel = useContext(EditorModelContext);
  const sideBarWidth = useSubscription(editorModel.$sideBarWidth);

  return (
    <Box
      data-testid="TimelineBox"
      sx={{
        background: '#fff',
        borderTop: '1px solid #ccc',
        bottom: 0,
        height: 190,
        left: `${sideBarWidth}px`,
        position: 'absolute',
        width: `calc(100vw - ${sideBarWidth}px)`,
        zIndex: 1001
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
