'use client';

import { FC, useContext } from 'react';

import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button/Button';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

export const PlayPauseButton: FC<ButtonProps> = props => {
  const editorModel = useContext(EditorModelContext);
  const videoElement = useSubscription(editorModel.player.$videoElement);
  const isPaused = useSubscription(editorModel.player.$isPaused);

  const handleClickPlay = () => {
    if (!videoElement) {
      throw new Error('Video element is not found');
    }
    void videoElement.play();
  };

  const handleClickPause = () => {
    if (!videoElement) {
      throw new Error('Video element is not found');
    }
    videoElement.pause();
  };

  return (
    <Button
      data-testit="PlayPauseButton"
      disabled={!videoElement}
      variant="contained"
      onClick={isPaused ? handleClickPlay : handleClickPause}
      {...props}
    >
      {isPaused ? 'Play' : 'Pause'}
    </Button>
  );
};
