import { editVideo } from './editVideo.api';

export const trimVideo = async (jwtToken: string, videoId: string, startTime: number, endTime: number) =>
  await editVideo(jwtToken, videoId, [
    {
      parameters: {
        endTime: endTime * 1000,
        startTime: startTime * 1000
      },
      type: 'TRIMMER'
    }
  ]);
