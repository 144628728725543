'use client';

import * as React from 'react';
import { FC, useContext } from 'react';

import { GradientCircularProgress } from 'common/components/GradientCircularProgress/GradientCircularProgress';
import { useSubscription } from 'common/hooks/useSubscription';
import { useVideoStatus } from 'common/pages/EditorPage/hooks/useVideoStatus';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

import { EmptyBox } from './EmptyBox';
import { VideoElement } from './VideoElement/VideoElement';

export const Canvas: FC = () => {
  const editorModel = useContext(EditorModelContext);
  const movieBase64 = useSubscription(editorModel.$videoData);

  useVideoStatus();

  if (!movieBase64) {
    return <EmptyBox>Upload or open a video</EmptyBox>;
  }

  if (movieBase64 === true) {
    return (
      <EmptyBox>
        <GradientCircularProgress />
      </EmptyBox>
    );
  }

  if (movieBase64 instanceof Error) {
    return <EmptyBox>Something went wrong</EmptyBox>;
  }

  return <VideoElement fileType="video/mp4" src={movieBase64} />;
};
