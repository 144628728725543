import { createContext } from 'react';

import { Atom, ReadOnlyAtom } from '@grammarly/focal';

import { Locale } from 'common/i18n/i18n.config';
import { Nullable, Undefined } from 'common/lib/types/assert';

import { EditorTranslations } from '../EditorTranslations.interface';

import { createPlayerModel, PlayerModel } from './PlayerModel';
import { EditorTool, VideoData } from './types';

export interface EditorState {
  readonly activeTool: Nullable<EditorTool>;
  readonly isToolsMenuOpened: boolean;
  readonly lang: Locale;
  readonly t: EditorTranslations;
  readonly videoData: Undefined<VideoData>;
  readonly videoId: Undefined<string>;
}

export class EditorModel {
  public readonly $activeTool: ReadOnlyAtom<Nullable<EditorTool>>;
  public readonly $isToolsMenuOpened: ReadOnlyAtom<boolean>;
  public readonly $lang: ReadOnlyAtom<Locale>;
  public readonly $videoId: ReadOnlyAtom<Undefined<string>>;
  public readonly $videoData: Atom<Undefined<VideoData>>;
  public readonly $sideBarWidth: ReadOnlyAtom<number>;

  public readonly player: PlayerModel;

  constructor(public readonly $state: Atom<EditorState>) {
    this.$activeTool = this.$state.lens('activeTool');
    this.$isToolsMenuOpened = this.$state.lens('isToolsMenuOpened');
    this.$videoId = this.$state.lens('videoId');
    this.$lang = this.$state.lens('lang');
    this.$videoData = this.$state.lens('videoData');

    this.$sideBarWidth = this.$isToolsMenuOpened.view(isOpen => (isOpen ? 240 : 65));

    this.player = createPlayerModel();
  }

  setTool(tool: EditorTool) {
    this.$state.modify(state => ({ ...state, activeTool: state.activeTool !== tool ? tool : null }));
  }

  toggleToolsMenu() {
    this.$state.modify(state => ({ ...state, isToolsMenuOpened: !state.isToolsMenuOpened }));
  }
}

const DEFAULT_EDITOR_STATE: EditorState = {
  activeTool: null,
  isToolsMenuOpened: true,
  lang: 'en',
  t: {} as never,
  videoData: undefined,
  videoId: undefined
};

export const createEditorModel = (state: Partial<EditorState> = {}) =>
  new EditorModel(Atom.create({ ...DEFAULT_EDITOR_STATE, ...state }));

export const EditorModelContext = createContext<EditorModel>(createEditorModel());
