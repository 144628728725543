import * as React from 'react';
import { FC, useContext, useState } from 'react';

import { Slider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { trimVideo } from 'common/api/trimVideo.api';
import { GradientCircularProgress } from 'common/components/GradientCircularProgress/GradientCircularProgress';
import { useAuth } from 'common/hooks/useAuth';
import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

function valuetext(value: number) {
  return `${value}°C`;
}

export const TrimSettings: FC = () => {
  const { token } = useAuth();
  const editorModel = useContext(EditorModelContext);

  const videoId = useSubscription(editorModel.$videoId);
  const videoTime = useSubscription(editorModel.player.$videoTime);

  const [value, setValue] = useState<number[]>([0, videoTime ?? 0]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (_: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const trim = async () => {
    if (!token || !videoId || !value[0] || !value[1]) {
      return;
    }
    try {
      setIsLoading(true);
      const result = await trimVideo(token, videoId, value[0], value[1]);
      console.log('result', result);
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsLoading(false);
    }
  };

  if (!videoTime) {
    return null;
  }

  return (
    <Box sx={{ p: 2, textAlign: 'center', width: '100%' }}>
      <Typography sx={{ mb: 4, mt: 4 }} variant="h3">
        Trim
      </Typography>
      <Box sx={{ ml: 2, mr: 2 }}>
        <Slider
          disabled={isLoading}
          getAriaLabel={() => 'Temperature range'}
          getAriaValueText={valuetext}
          max={videoTime}
          min={0}
          value={value}
          valueLabelDisplay="on"
          onChange={handleChange}
        />
        {isLoading ? (
          <GradientCircularProgress />
        ) : (
          <Button sx={{ mt: 4 }} variant="contained" onClick={trim}>
            Trim
          </Button>
        )}
      </Box>
    </Box>
  );
};
