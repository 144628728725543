import { useCallback, useContext, useEffect } from 'react';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

import { useResizeVideo } from './useResizeVideo';

export const useVideoElement = (onLoad?: (video: HTMLVideoElement) => void) => {
  useResizeVideo();

  const editorModel = useContext(EditorModelContext);
  const videoElement = useSubscription(editorModel.player.$videoElement);

  useEffect(() => {
    if (!videoElement) {
      return;
    }

    const handleVideoLoaded = () => {
      onLoad?.(videoElement);
      editorModel.player.setVideoTime(videoElement.duration || null);
      editorModel.player.pause();
    };

    const handleTimeUpdate = () => {
      console.log(`Current video time: ${videoElement.currentTime}`);
      editorModel.player.setCurrentTime(videoElement.currentTime);
    };

    videoElement.load();
    videoElement.addEventListener('loadeddata', handleVideoLoaded);
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('loadeddata', handleVideoLoaded);
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [editorModel.player, onLoad, videoElement]);

  const setRef = useCallback(
    (element: HTMLVideoElement) => {
      editorModel.player.setVideoElement(element);
    },
    [editorModel.player]
  );

  return { setRef };
};
