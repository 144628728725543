'use client';

import * as React from 'react';
import { FC } from 'react';

import { useVideoElement } from './useVideoElement';

interface Props {
  fileType: string;
  onLoad?: (video: HTMLVideoElement) => void;
  src: string;
}

export const VideoElement: FC<Props> = ({ fileType, onLoad, src }) => {
  const { setRef } = useVideoElement(onLoad);

  return (
    <video data-testid="VideoElement" ref={setRef} controls>
      <source
        src={src}
        type={fileType}
        onError={e => {
          console.log('error', e);
        }}
        onLoad={e => {
          console.log('e', e);
        }}
      />
    </video>
  );
};
